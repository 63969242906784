const config = {
  base: '/web-components-assets/'
};
export function setBase(url) {
  config.base = url;
}
export function asset(path) {
  return config.base + path;
}
export default asset;
